body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.table-striped>tbody>tr:nth-of-type(odd) {
  --bs-table-accent-bg: rgba(74, 159, 194, 0.19);
}

a {
    text-decoration: none;
}


/* .item must be first */
.item {
  align-items: center;
  color: #FFF;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 40px;
  justify-content: center;
  width: 40px;
}

.disabled-page {
  color: #4d9bbc;
}

.active_pagination {
  border: solid 1px #4d9bbc;
  border-radius: 40px;
  color: #4d9bbc;
}

.break-me {
}

.next {
  border-left: solid 1px #4d9bbc;
  font-size: 20px;
  height: 60px;
  position: absolute;
  right: 0;
  width: 150px;
}

.pagination {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  list-style-type: none;
  position: relative;
  width: 100%;
  border: #4d9bbc 1px solid;
  border-radius: 7px;
}

.pagination-page {
  font-weight: 700;
}

.previous {
  border-right: solid 1px #4d9bbc;
  font-size: 20px;
  height: 60px;
  left: 0;
  position: absolute;
  width: 150px;
}


/* navbar */

.nav-item>.nav-link {
  color:#FFFFFF!important;
}
.nav-item>.active {
    background-color: white;
  border-radius: 7px;
  color: #4d9bbc!important;
}

.nav-item>.espacelocations {
  background-color: #6E6F71;
  border-radius: 7px;
  color: white!important;
}

/* carousel */
.fc-left > h2{
  font-size: 16px;
  text-transform: capitalize;

}

.calendarHidden {
    display: none;
}
